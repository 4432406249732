.audits-container{
    width: calc(100% - 240px);
    height: 100%;
    background-color: #FCFCFC;
    display: grid;
    grid-template-columns: 70% 30%;
}

.audits-wrapper{
    display: flex;
    flex-direction: column;
    height: calc(100vh - 40px);
}


.audits_filter_datepicker_wrapper{
    width: 218px;
}

.audits_filter_datepicker_wrapper > div > div.react-datepicker__input-container input{
    height: 28px;
    width: 210px;
    border-radius: 5px;
    outline: none;
    box-shadow:none;
    border: 1px solid #A4A4A4;
    padding: 0px 5px
}

.react-datepicker__input-container input:focus{
    outline:none;
    box-shadow:none;
}

.pagination-container{
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.pagination-container::-webkit-scrollbar{
    display: none;
}