.PopupPage-Wrapper{
    position: fixed;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(78, 78, 78, 0.85);
    cursor: pointer;
}

.PopupPage-Container{
    width: 700px;
    height: 100vh;
    z-index: 1000;
    position: fixed;
    top: 0;
    right: -700px;
    animation: slide 0.25s forwards;
    box-shadow: -10px 0px 10px 1px #454545;
    cursor: default;
}

.PopupPage-CloseBtn:hover{
    cursor: pointer;
    background-color: #d5d5d5;
    border-radius: 50%;
}

@media (max-width: 700px){
    .PopupPage-Container{
        max-width: calc(100vw - 30px);
    }
}

@-webkit-keyframes slide {
    100% { right: 0; }
}

@keyframes slide {
    100% { right: 0; }
}

.PopupPageTypeButton{
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
}

.PopupPageTypeButton-Selected{
    text-decoration: underline;
    background-color: #d5d5d5;
    padding: 5px 10px;
    border-radius: 5px;
}

.PopupPageTypeButton:hover{
    background-color: #d5d5d5;
    text-decoration: underline;
}