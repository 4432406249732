.vuln-description-container{
    min-height: 840px;
    height: 100%;
    border-radius: 2px;
    background: #FCFCFC;
    border: 1px solid #c4c4c4;
    margin-top: 5px;
    margin-right: 5px;
}

.vuln-description-title-wrapper{
    text-align: center;
    margin-top: 10px;
}

.vuln-description-id-list{
    display:flex;
    justify-content: space-around;
    border-bottom: 3px solid #C7C7C7;
    padding: 5px;
}

.vuln-description-content-wrapper{
    display:flex;
    flex-direction: column;
    margin: 5px;
    height: 500px;
    overflow-y: scroll;
}

.vuln-description-content-wrapper h4, .vuln-description-content-comment-wrapper h4{
    font-weight: bold;
}
.vuln-description-content-comment-wrapper{
    width: 100%;
    border-top: 3px solid #C7C7C7;
    display:flex;
    flex-direction: column;
    margin: 5px;
}