.filter-search-container{
    background: #FCFCFC;
    height: 50%;
    border: 1px solid #c4c4c4;
    margin-left: 5px;
}

.filter-search-wrapper{
    padding: 5px;
    height: 90%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.filter-search-header-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.filter-button{
    padding: 5px;
    background-color: #c4c4c4;
    border: 1px solid #878787;
    margin: 5px;
    color: "#fff";
    cursor: pointer;
    border-radius: 2px;
    min-width: 70px;
}