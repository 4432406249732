.login-form-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EBEBEB;
}

.login-form-wrapper{
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    border-radius: 15px;
    padding: 20px;
    width: 350px;
    min-height: 350px;
    border: 1px solid #EBEBEB;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
}

.login-form{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.login-form > h1{
    line-height: 40px;
}

.login-form > label > input{
    width: 100%;
    height: 32px;
    border-radius: 5px;
    border: 1px solid #a4a4a4;
    outline: none;
    text-indent: 5px;
}


.login-form > label{
    width: 80%;
}

.login-img-wrapper{
    position: relative;
    bottom: 0px;
    right: 0;
}

.login-img{
    position: absolute;
    right: 0;
    bottom: 0;
}

.login-branding-container{
    position: absolute;
    width: 100%;
    height: 60px;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.15);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
}

.login-branding-wrapper{
    color: #fff;
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    font-size: 16px;
    padding-left: 20px;
}

.login-branding-logo{
    max-height: 60px;
}
