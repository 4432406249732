*{
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
}

.App{
  background-color: #E0E0E0;
}

h4{
  font-weight: 100;
}

.header{
  width: 100%;
  height: 20px;
  background: linear-gradient(180deg, #DADADA 0%, #C4C4C4 100%);
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0;
  margin: 0;
}

.header h4{
  padding-left: 5px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1;
} */

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


#form-file-upload {
  height: 100%;
  min-height: inherit;
  max-width: 100%;
  text-align: center;
  position: relative;
  z-index: 9999;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  min-height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.center-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-left-container{
  display: flex;
  align-items: center;
}

.text-center{
  text-align: center;
}


/* Tables */
.inventory-table{
  border: 1px solid #DCDCDC;
  height: calc(100vh - 145px);
  border-radius: 5px 5px 0 0;
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100% - 32px); 
  margin: 0px 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.inventory-table-header{
  width: 100%;
  height: 35px; 
  display: grid;
  grid-template-columns: 20px 2fr 1fr 1fr 1fr 1fr;
  border-radius: 5px 5px 0 0;
}

.inventory-table-row{
  width: 100%;
  height: 35px; 
  display: grid;
  grid-template-columns: 20px 2fr 1fr 1fr 1fr 1fr;
  cursor: pointer;
  border-bottom: 1px solid #DCDCDC;
}

.inventory-table-animation{
  margin: 5px 0px 5px 40px;
  animation: 0.5s table-open ease;
}

@media (max-width: 1280px){
  .inventory-content{
      width: calc(100% - 240px);
      display: grid;
      grid-template-columns: 100%;
  }

  .inventory-content-container{
      align-items: center;
  }

  .inventory-table-wrapper{
      width: calc(100% - 20px);
      margin: 10px;
  }
}

@keyframes table-open {
  from {
      max-height: 0px;
  }
  
  to {
      max-height: 1000px;
  }
}

