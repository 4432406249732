.info-graph-container{
    background: #FCFCFC;
    height: 50%;
    border: 1px solid #c4c4c4;
    margin-left: 5px;
    margin-top: 5px;
}

/* FormGroup from MUI doesn't allow className */
.info-grid-form-group{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.info-graph-table{
    width: 95%;
    table-layout: fixed;

}

.info-graph-table td{
    border:none;
}

.info-graph-graph-container{
    display: flex;
    justify-content: center;
}