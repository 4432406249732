.profile-navlink{
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 10px 20px;
    height: 20px;
}

.profile-navlink:hover{
    text-decoration: underline;
}

.profile-admin-user-table{
    border-collapse: collapse;
}

.profile-admin-user-table thead tr th, .profile-admin-user-table tbody tr td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.notificationCard{
    color: black;
    margin-bottom: 10px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding: 10px;
    border-left: 5px solid lightblue;
    transition: border-left-width 0.1s ease-in-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.notificationCard:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    border-left-width: 10px;
}