html{
    scroll-behavior: smooth;
}

.dashboard-spacer{
    width: 100%;
    /* height: 24px; */
    background-color: #A4A4A4;
}

.dashboard-content{
    width: 100%;
    min-height: calc(100vh - 40px);
    background-color: #FDFDFD;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.sidebar-container{
    width: 240px;
    height: "100%";
    background-color: #3F3F3F;
    display: flex;
    flex-direction: column;
    min-height: 500px;
}

.sidebar-wrapper{
    display: flex;
    flex-direction: column;
}

.sidebar-wrapper h3{
    color: #9F9F9F;
    font-size: 16px;
    font-weight: normal;
    padding-left: 20px;
    margin-top: 30px;
}

.sidebar-wrapper span{
    padding: 5px;
    padding-Left: 20px;
    display: flex;
    align-items: center;
}

.splash-screen-progress-bar{
    background-color: #093471;
    height: 8px;
    border-radius: 15px;

}

.sidebar-navlink{
    display: flex;
    align-items: center;
    padding: 5px 20px;
    color: #FFF;
    text-decoration: none;
}

.sidebar-navlink:hover{
    text-decoration: underline;
}

.sidebar-navlink-active{
    display: flex;
    align-items: center;
    padding: 5px 20px;
    color: #3F3F3F;
    background-color: #DFDFDF;
    text-decoration: none;
}

@keyframes progressbar {
    from {
        width: 0%;
    }
    
    to {
        width: 100%
    }
}