.vuln-table-container{
    width: 100%;
    min-height: 840px;
    height: 100%;
    border-radius: 2px;
    background: #FCFCFC;
    border: 1px solid #c4c4c4;
    margin-top: 5px;
}

.scrollbar-wrapper{
    overflow-y:scroll;
    height: 97.5%;
    margin: 0;
    padding: 0;

}

.table-container{
    border-collapse: collapse;
    width: 100%;
    overflow-y: scroll;
    table-layout: fixed;
}

.table-row td{
    border: 1px solid #c4c4c4;
    padding: 5px;
    cursor: pointer;
}

.table-row-no-color td{
    border: 1px solid #c4c4c4;
    padding: 5px;
    cursor: pointer;
}

.table-row th {
    top: 0;
    text-align: left;
    padding: 5px;
}


.selected > *{
    background-color: #87cefa;

}

.table-head{
    border: 1px solid #c4c4c4;
}
