.inventory-content{
    width: calc(100% - 240px);
    display: grid;
    grid-template-columns: 70% 30%;
}

.inventory-content-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inventory-content-header{
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0px 16px;
}

.inventory-content-header-button{
    display: flex;
    margin-right: 10px;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.inventory-content-header-button:hover{
    background-color: #EEEEEE;
}

.inventory-table-group-arrow-button:hover{
    background-color: #CCCCCC;
    border: 1px solid #ffffff;
}

.inventory-content-wrapper{
    width: 100%;
    height: auto;
    min-height: 500px;
}

.inventory-widgets-container{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
}

.inventory-table-wrapper{
    border: 1px solid #DCDCDC;
    height: 100%;
    border-radius: 5px 5px 0 0;
    overflow-y: auto;
    width: calc(100% - 32px); 
    margin: 0px 16px;
}

.inventory-table-container{
    width: 100%;
    border-collapse: collapse;
}

.inventory-table-header-row{
    height: 36px;
    background-color: #D7D7D7;
    border-radius: 5px 5px 0 0;
    width: 100%;
}

.inventory-table-body-row{
    height: 36px;
    border-bottom: 1px solid #DCDCDC;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

.inventory-table-body-row:hover{
    background-color: #eeeeee93;
}

.device-table-row:hover{
    background-color: #eeeeee93;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

.stig-table-row:hover{
    background-color: #eeeeee93;
}

.pirority-high{
    background-color: #fC4C4C;
    padding: 0 10px 0 10px;
    border-radius: 5px;
    color: #ffffff
}