.navigation-container{
    width: 100%;
    height: 40px;
    background-image: linear-gradient(#454545, #353535);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.navigation-menu{
    display: flex;
    flex-direction: row;
    align-items: center; 
    height: 100%; 
    background-image: linear-gradient(#454545, #353535); 
    margin-left: 12px;
}

.navigation-navlink{
    padding: 15px;
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
    color: #fff;
}

.navigation-navlink-active{
    padding: 15px;
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
    color: #fff;
}

.stiglist-stigbutton{
    height: 15px;
    margin: 0;
    background-color:#727272;
    margin-left: 10px;
    border-radius: 3px 3px 0 0;
    border:none;
    color:White;
    cursor: pointer;
    padding: 0 5px 0 5px;
    z-index: 0;
    white-space: nowrap;
}

.stiglist-closebutton{
    cursor:pointer;
    margin-left: 5px;
    padding: 0 3px 0 3px;
    color: white;
    border:none;
    background: rgba(0, 0, 0, 0);
}

.stiglist-container{
    width: 100vw; 
    background-color: #A4A4A4;
    height: 30px;
    display: flex; 
    align-items:flex-end;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none; 
}

.stiglist-container::-webkit-scrollbar{
    display:none;
}

.stiglist-select{
    background-color: #E0E0E0;
    color:black;
}

.stiglist-select input{
    color:black;
}

.basic-button{
    color: white;
    font-size: 12px;
    height: 100%;
}

.menu-item{
    height: 14px;
    font-size: 12px;
    background: #454545;
}

.menu-item:hover{
    color: "#ff0000"
}

.navigation-menu-title{
    width: 60px;
    cursor: pointer;
    height: 100%;
    color:white;
    font-size: 12px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navigation-menu-title:hover{
    background-color: #252525;
}

.navigation-menu-list{
    position:absolute;
    top: 32px;
    background: #454545;
    display: flex;
    flex-direction: column;
    z-index: 100;
    padding: 0 0 5px 0;
    border-radius: 0 0 3px 3px;
}

.navigation-menu-link{
    text-decoration: none;
    color: #fff;
    white-space: nowrap;
    text-align: left;
    padding:5px 10px;
    min-width: 100px;
}

.navigation-menu-link:hover{
    background: #6e6e6e;
}

.navigation-submenu-popup{
    background-color: #353535;
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    display:flex;
    justify-content: center;
    align-items: center;
    opacity: 0.95;
}

.navigation-profile-button{
    width: 30px;
    height: 30px;
    margin-left: 15px;
    cursor:pointer;
    transition: filter 300ms;
}

.navigation-profile-button:hover{
    filter: brightness(1.5);
}

.navigation-profile-dropdown{
    position: absolute;
    right: 0;
    width: 200px;
    background-color: #353535;
    border: 1px solid #545454;
    border-radius: 5px;
    transform: translateX(-10px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 100;
}

.navigation-profile-link{
    height: 20px;
    display: flex;
    align-items: center;
    transition: all 300ms;
    padding: 0.5rem;
    color: #FFF;
    text-decoration: none;
    justify-content: space-evenly;
    width: 92%;
}

.navigation-profile-link:hover{
    background-color: #545454;
    width: 92%;
}