.tableofcontent-list-item, .tableofcontent-list-subitem{
    list-style: none;
    padding-bottom: 5px;
    letter-spacing: 1px;
    cursor: pointer;
    text-decoration: none;
    color: #999;
}

.tableofcontent-list-item-active{
    list-style: none;
    padding-bottom: 5px;
    letter-spacing: 1px;
    cursor: pointer;
    text-decoration: underline;
    color: #000;
}

.tableofcontent-list-item span, .tableofcontent-list-subitem a{
    padding-left: 5px;
}

.tableofcontent-list-item::before, .tableofcontent-list-subitem::before, .tableofcontent-list-item-active::before{
    content: '\00bb';
    font-weight: bold;
}

.tableofcontent-list-item:hover, .tableofcontent-list-subitem:hover{
    text-decoration: underline;
}

.documentation-unordered-list{
    margin-left: 25px;
}

.documentation-unordered-list li{
    padding: 10px 0px;
}

.documentation-table{
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 90%;
}

.documentation-subsection-title{
    font-size: 32px;
    color: #2B2B2B;
    line-height: 48px;
    font-weight: normal;
    padding-top: 20px;
}

.documentation-table tbody tr td, .documentation-table thead tr th{
    border: 1px solid #A4A4A4;
    text-align: left;
    padding: 8px;
}

.documentation-table thead tr{
    background-color: #dddddd;
}

.documentation-table tbody tr:nth-child(even){
    background-color: #dddddd;
}

.documentation-button{
    height: 38px;
    border: 2px solid #A4A4A4;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 3px 1px rgb(0 0 0 / 10%);
    min-width: 80px;
    padding: 0px 10px;
}

.documentation-container{
    opacity: 1;
    animation: 1s fadein;
    padding-bottom: 50px;
}

.documentation-container p{
    width: 90%;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    
    to {
        opacity: 1;
    }
}