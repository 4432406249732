.playbooks-widget-table-container::-webkit-scrollbar {
    width: 16px;
}
 
.playbooks-widget-table-container::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
}

.playbooks-widget-table-container::-webkit-scrollbar-thumb {
    background-color: #9f9f9f;
    border-radius: 10px;
}