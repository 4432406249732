.widget-container{
    padding: 16px;
}

.widget-header{
    border-bottom: 1px solid #9f9f9f;
    padding: 5px 0px;
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.widget-header-edit{
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
}

.widget-content{
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.widget-text{
    display: grid;
    grid-template-columns:1fr 2fr;
    gap: 10px;
    align-items: center;
    overflow: hidden;
    padding-bottom: 5px;
}

.widget-text span{
    /* overflow: hidden; */
}

.widget-text-no-grid{
    display: flex;
    align-items: center;
}

.widget-text-variables{
    display: grid;
    grid-template-columns:20px 1fr 1fr;
    align-items: center;
    margin-bottom: 5px;
}


.widget-button{
    min-width: 100px;
    width: fit-content;
    height: 30px;
    background-color: #7f7f7f;
    color: #ffffff;
    opacity: 1;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px 10px;
    padding: 5px;
}

.widget-button-disable{
    min-width: 100px;
    width: fit-content;
    height: 30px;
    background-color: #7f7f7f;
    color: #ffffff;
    opacity: 0.5;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px 10px;
    padding: 5px;
    cursor: default;
}

.widget-text-input{
    height: 28px;
    width: 200px;
    border-radius: 5px;
    box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.15);
    margin-left: 10px;
}

.widget-select{
    width: 100px;
    height: 30px;
    border-radius: 5px;
    border-color: #A4A4A4;
    margin: 5px 10px;
    cursor: pointer;
}

.widget-button-settings{
    width: 30px;
    height: 30px;
    margin: 10px 15px 10px 10px;
    background-color: #A4A4A4;
    color: #ffffff;
    opacity: 1;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.widget-textfield{
    padding: 3px;
    width: 90%;
}

.widget-delete-var-button{
    color: #f45454;
    cursor: pointer;
}

.widget-more-detail{
    color: #0088ff;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: flex-end;
}

.widget-progress-bar{
    animation: 1s progressbar ease-in-out;
    animation-fill-mode: forwards;
    width: 0%;
}

@keyframes progressbar {
    from {
        width: 0%;
    }
    
    to {
        width: 100%
    }
}
