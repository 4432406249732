.grid-container{
    background-color: #E0E0E0;
    min-width: 100vh;
}

.grid-item-header{
    width: 100%;
    height: 20px;
    background: linear-gradient(180deg, #DADADA 0%, #C4C4C4 100%);
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0;
    margin: 0;
}
  
.grid-item-header h4{
    padding-left: 5px;
}
  